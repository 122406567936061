.fuel-prices-page {
    width: 100%;
    min-width: 940px;
    display: flex;
}

.fuel-prices-cards-area {
    display: grid;
    gap: 50px 100px;
    grid-template-rows: auto;
    grid-template-columns: 250px 250px 250px;
}