.change-pass-body-btns {
    display: flex;
    margin-top: 15px;
}

.forgot-password-success-send-email {
    color: green;
}

.forgot-password-error-send-email {
    color: red;
}