.forgot-password {
    margin-top: 10px;
    color: gray;
    text-align: end;
    margin-right: 45px;
    font-size: 12px;
}

.forgot-password-text {
    cursor: pointer;
}

.forgot-password-text:active {
    color: lightblue;
}