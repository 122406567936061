.not-found-page {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-div {
    text-align: center;
}

.error-404-div {
    font-size: 60px;
}

.error-404-div > h1 {
    margin-bottom: 0;
}

.home-button-div {
    display: flex;
    justify-content: center;
}