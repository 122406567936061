.stations-back-stations-groups-link {
    margin-top: 0;
    cursor: pointer;
    transition: 0.3s;
}
.stations-back-stations-groups-link:hover {
    opacity: 0.7;
}

.stations-page-pagination {
    display: flex;
    justify-content: center;
}